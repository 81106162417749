<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="page-title">
            {{
              $route.name == "trainer-officer-credits-create"
                ? "Add Credit"
                : "Edit Credit"
            }}
          </h4>

          <div
            class="col-2"
            v-if="$route.name == 'trainer-officer-credits-create'"
          >
            <v-select
              class=""
              name="formType"
              :clearable="false"
              id="formType"
              v-model="selectedFormType"
              :options="formTypes"
              @option:selected="
                (val) => {
                  credit = {};
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples" v-if="selectedFormType == 'Single'">
              <div class="row mb-3">
                <label for="name" class="col-md-3 form-label">
                  Credit Name
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-officer-credit-criteria-create',
                      'trainer-officer-credit-criteria-edit',
                    ])
                  "
                >
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control"
                    placeholder="Credit Name"
                    v-model="credit.name"
                    :class="{
                      'p-invalid': v$.credit.name.$error || errorFor('name'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('name')"
                    :vuelidateErrors="{
                      errors: v$.credit.name.$errors,
                      value: 'Credit Name',
                    }"
                  ></v-errors>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-officer-credit-criteria-list'])
                  "
                >
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control"
                    placeholder="Credit Name"
                    v-model="credit.name"
                    disabled
                  />
                </div>
              </div>

              <div class="row mb-3">
                <label for="score" class="col-md-3 form-label">
                  Credit Score
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-officer-credit-criteria-create',
                      'trainer-officer-credit-criteria-edit',
                    ])
                  "
                >
                  <input
                    type="number"
                    id="score"
                    name="score"
                    class="form-control"
                    oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                    placeholder="Credit Score"
                    v-model="credit.score"
                    :class="{
                      'p-invalid': v$.credit.score.$error || errorFor('score'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('score')"
                    :vuelidateErrors="{
                      errors: v$.credit.score.$errors,
                      value: 'Credit Score',
                    }"
                  ></v-errors>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-officer-credit-criteria-list'])
                  "
                >
                  <input
                    type="number"
                    id="score"
                    name="score"
                    class="form-control"
                    oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                    placeholder="Credit Score"
                    v-model="credit.score"
                    disabled
                  />
                </div>
              </div>

              <div
                class="row mb-3"
                v-if="currentRouteName === 'trainer-officer-credits-update'"
              >
                <label for="status" class="col-md-3 form-label">
                  Status
                  <!-- <span class="text-danger">*</span> -->
                  <!-- :disabled="
                        currentRouteName == 'trainer-update' ? false : true
                      " -->
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-officer-credit-criteria-create',
                      'trainer-officer-credit-criteria-edit',
                    ])
                  "
                >
                  <select name="status" v-model="status" class="form-control">
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-officer-credit-criteria-list'])
                  "
                >
                  <select
                    name="status"
                    v-model="status"
                    class="form-control"
                    disabled
                  >
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                </div>
              </div>

              <div class="row mb-3" v-if="!status">
                <label for="remark" class="col-md-3 form-label">
                  Remark
                  <!-- <span class="text-danger">*</span> -->
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-officer-credit-criteria-create',
                      'trainer-officer-credit-criteria-edit',
                    ])
                  "
                >
                  <textarea
                    type="text"
                    id="remark"
                    name="remark"
                    class="form-control"
                    placeholder="Remark"
                    v-model="remark"
                    :class="{
                      'p-invalid': v$.remark.$error || errorFor('remark'),
                    }"
                  >
                  </textarea>
                  <v-errors
                    :serverErrors="errorFor('remark')"
                    :vuelidateErrors="{
                      errors: v$.remark.$errors,
                      value: 'Remark',
                    }"
                  ></v-errors>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-officer-credit-criteria-list'])
                  "
                >
                  <textarea
                    type="text"
                    id="remark"
                    name="remark"
                    class="form-control"
                    placeholder="Remark"
                    v-model="remark"
                    disabled
                  >
                  </textarea>
                </div>
              </div>
              <!-- end row -->
              <div
                class="row"
                v-if="
                  hasPermissions([
                    'trainer-officer-credit-criteria-create',
                    'trainer-officer-credit-criteria-edit',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'trainer-officer-credits' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'trainer-officer-credits-update'
                          ? updateCriteria()
                          : createCriteria()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>

            <MultipleCreate v-if="selectedFormType == 'Multiple'" />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";
import MultipleCreate from "./MultipleCreate.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    MultipleCreate,
    vSelect
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      credit: {
        name: "",
        score: "",
      },
      status: 1,
      remark: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      formTypes: ["Single", "Multiple"],
      selectedFormType: "Single",
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      credit: {
        name: { required },
        score: { required },
      },
      remark: {
        required: requiredIf(() => {
          return !this.status;
        }),
      },
    };
  },
  methods: {
    async getCriteriaById() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v2/officer-credit/${this.$route.params.id}`)
        .then((response) => {
          const result = response.data.data;
          this.credit = result;
          this.credit.name = result.name;
          this.credit.score = result.score;
          this.status = result.status;
          this.remark = result.remark;
        })
        .catch(() => {
          this.toast.error("Not Found Criteria!");
        });
      this.loading = false;
    },

    async createCriteria() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;

      await axios
        .post(`${this.baseUrl}admin/v2/officer-credit`, this.credit)
        .then(() => {
          this.$router.push({ name: "trainer-officer-credits" });
          this.toast.success("Successfully Created Criteria!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async updateCriteria() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      if (this.status) this.remark = null;
      let data = {
        name: this.credit.name,
        score: this.credit.score,
        status: this.status,
        remark: this.remark,
      };
      this.isLoading = true;
      axios
        .put(
          `${this.baseUrl}admin/v2/officer-credit/${this.$route.params.id}`,
          data
        )
        .then(() => {
          this.$router.push({ name: "trainer-officer-credits" });
          this.toast.success("Successfully Criteria Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
  },
  created() {
    if (this.currentRouteName == "trainer-officer-credits-update") {
      this.getCriteriaById();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
